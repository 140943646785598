import React from 'react';
import logo from '../img/insurance321_logo_white.svg'
import Footer from '../components/Footer';
import Session321 from '../components/Session321'
import  "../styles/nomination.css"
import { Helmet } from "react-helmet"
import NominationForm from '../components/BusinessNomination';
import { Link } from 'gatsby'
import AboutUs from '../components/AboutUs'
import Nav from '../components/Nav'
import VideoElement from '../components/VideoElement'
import PlayButton from '../img/play-button.svg'
import CloseButton from '../img/CloseButton.svg'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, } from "react-share";
import {  FacebookIcon, LinkedinIcon, TwitterIcon, } from "react-share";
const SmallBusiness = () => {
    const shareUrl = "https://insurance321.com/small-business/"
    const shareTitle = "Insurance321 Supports Small Business"
    const shareTag = "#rocketrebound"
    const shareSummary = "Insurance321 has created a business support program. The program helps small businesses with marketing and technology at no cost to the business."
    const shareSource = "https://insurance321.com/small-business/"

        return (
            <div id="sb_container">
            <div id="video_overlay"><VideoElement /></div>
            <div id="small_business">
                <Helmet>
                    <title>Insurance321 Small Business Support Program</title>
                    <meta name="description" content="Insurance321 is helping small businesses with marketing and technology to help them bounce back from the trouble of the past year"></meta>
                    <meta property='og:title' content='Insurance321 Small Business Support Program'/>
                    <meta property='og:image' content='https://insurance321.com/img/cheers-to-small-business-og-img.png'/>
                    <meta property='og:description' content='Insurance321 is helping small businesses with marketing and technology to help them bounce back from the trouble of the past year'/>
                    <meta property='og:url' content='https://insurance321.com/small-business'/>
                    <Session321 />
                </Helmet>
                
                <div id="sb_nav_bar">
                    <div className="content-width">
                        <Link to="/media-home/"><div id="logo"><img src={logo} alt="insurance321 logo" /></div></Link>
                        <Nav />
                    </div>
                </div>
                <div id="close_button">
                    <img src={CloseButton} alt="Close button" width="50px" />
                </div>
                <div id="nomination_hero">
                    <div id="share_icons">
                        <FacebookShareButton url={shareUrl} title={shareTitle} hashTag={shareTag} summary={shareSummary}>
                            <FacebookIcon size={50} />
                        </FacebookShareButton>
                        <LinkedinShareButton url={shareUrl} title={shareTitle} source={shareSource} summary={shareSummary}>
                            <LinkedinIcon size={50}/>
                        </LinkedinShareButton>
                        <TwitterShareButton url={shareUrl} title={shareTitle} hashTag={shareTag} summary={shareSummary}>
                            <TwitterIcon size={50} />
                        </TwitterShareButton>

                    </div>
                    <div className="content-width">
                        <h1>Know a business that is bouncing back from the past year?</h1>
                        <div id="play_button" className="play-button">
                            <img src={PlayButton} alt="Play button" width="70px" />
                            <h3>Play Campaign Video</h3>
                        </div>
                    </div> 
                </div>
                
                <div id="form_container">
                    <div className="container-left"><NominationForm></NominationForm></div>
                    <div className="container-right">
                        <h3>The Rocket Rebound Process</h3>
                        <p>First, we'd like to say we appreciate that you've taken the time to help a small business. We'll do our best to make it worth your while.</p>
                        <p>Tell us a little about the business you're nominating using this form (you can nominate your own business). Our team will review responses and select a business based on our assessment of how much, and how quickly, we can help.</p>
                        <p>Once we select a new business, we'll schedule an introductory call to talk through ways that we may be able to support the business.</p>
                        <p>Support services (at no cost to the business) may include:</p>
                            <ul>
                                <li>Local Marketing</li>
                                <li>Website design and/or development</li>
                                <li>Help with business listings and/or social profiles</li>
                                <li>Help setting up advertising accounts in Google and Facebook</li>
                                <li>Help finding and applying for small business advertising grants</li>
                                <li>Design, creative, or copywriting support</li>
                                <li>...Anything else the team thinks we can do in 40 hours. </li>
                            </ul>
                    </div>
                </div>
                <div id="form_container_mobile">
                    <div className="container-right">
                        <h3>The Rocket Rebound Process</h3>
                        <p>First, we'd like to say we appreciate that you've taken the time to try to help a small business. We'll do our best to make it worth your while.</p>
                        <p>Tell us a little about the business you're nominating using this form (you can nominate your own business). Our team will review responses and select a business based on our assessment of how much, and how quickly, we can help.</p>
                        <p>Once we select a new business, we'll schedule an introductory conversation to talk through ways that we may be able to support the business with digital marketing, a website, design... Whatever the team here can do in 40 or so hours. (At no cost to the business.)</p>
                    </div>
                    <div className="container-left"><NominationForm></NominationForm></div>
                </div>
                <AboutUs />
                <Footer />
            </div>
        </div>
        )
    
}
 
export default SmallBusiness;