import React, { useEffect, useState } from 'react';

import  "../styles/circles.css"
import User from './User';
import { Link } from 'gatsby'

export default function NominationForm (props) {
    const [value, setValue] = useState()
    const [tcpa, setTcpa] = useState(1)
    const [checked, setChecked] = useState(true)
    const handleChange = () => {
        setChecked(!checked)
        if (checked == true) {
            setTcpa(1)
        }
        else if (checked == false) {
            setTcpa(null)
        }
    }

    return (
        <div id ="nomination">
            <h3>Business Nomination</h3>
                <form acceptCharset="UTF-8" id="nomination_form" action="https://getform.io/f/91dea62a-7416-40ef-93cb-12ba3578c796" method="POST" >
                    <input type="text"  name="business_name" placeholder="Business Name" required></input>
                    <input type="email" name="business_email" placeholder="Business Email" required></input>
                    <input type="tel" name="business_phone" placeholder="Business Phone (format ###-###-####)" pattern="(?:(?:(\s*\(?([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\)?\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})" maxLength={14} minLength={10} required></input>
                    <input type="text"  name="zip_code" placeholder="Business Zip Code" maxLength={5} minLength={5} required></input>
                    <input type="url"  name="business_domain"  placeholder="Website (must begin with http:// or https://)" maxLength={40} minLength={5}></input>
                    <input type="text"  name="nominator_name" placeholder="Your Name" required maxLength={20} minLength={2}></input>
                    <input type="email" name="nominator_email" placeholder="Your email" required></input>
                    <textarea id="description " name="description" placeholder="Tell us about the business you're nominating" rows="4" cols="50"></textarea>
                    <User />
                    <div className="button-container">
                        <button className="request-quote" type="submit">Submit</button>
                    </div>
                    <div className="disclaimer">
                        <span class="disclaimer-checkbox"><input type="checkbox" name="privacy_disclosure" required onChange={handleChange} ></input></span>
                        <input type="hidden" name="tcpa" value={tcpa||'1'} />
                        <span className="disclaimer-text">In order for your nomination to be considered, you must agree to the program <a href='/participation-agreement' target="_blank"><div class="link">Participation Agreement</div></a> and the Insurance321 <a href='/privacy-policy' target="_blank"><div class="link">Privacy Policy</div></a></span>
                    </div>
                </form>
        </div>
    )
}